<template>
    <div class="row" style="margin: 0;" id="box-app-pd-01-title-t">
        <div class="col-7">
            <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02" v-show="!isToken">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16"   v-on:click="pagenotificacoes('Meucadastro')">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>
            </div>
            <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02" v-show="isToken">

                <b-avatar variant="info" :src="'https://placekitten.com/300/300'"  v-show="isFotousuario"   v-on:click="pagenotificacoes('Meucadastro')" size="4rem"></b-avatar>

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"  v-show="!isFotousuario" class="bi bi-person-circle" viewBox="0 0 16 16"   v-on:click="pagenotificacoes('Meucadastro')">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>

            </div>
        </div>
        <div class="col-5">
            <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01"> 
                <div id="btn-header-visualizar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16"  v-on:click="pagenotificacoes('Qrcode')">
                    <path d="M2 2h2v2H2V2Z"/>
                    <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
                    <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
                    <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
                    <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
                    </svg>  
                </div>  
                <div id="btn-header-notificacoes">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16"  v-on:click="pagenotificacoes('Notificacoes')">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                    </svg>
                    <i v-show="isNotificacoescount"  v-on:click="pagenotificacoes('Notificacoes')">{{notificacoescount}}</i>
                </div> 
                <div id="btn-header-menu">
                    <a class=" btn btn-primary "   v-on:click="openmenuprincipal()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>  
                    </a>    
                </div> 
            </div>
        </div>
    </div>
</template>

 
<script>
import barramento from '@/barramento'

export default {
  name: 'AppTitle', 
  data: function() {
    return {
        notificacoescount: 2,
        fotousuario: '',
        isLoading: false,
        isToken: false,
        isFotousuario: false,
        isNotificacoescount: true
    }
  },
  methods: {
    pagenotificacoes(name){   
        barramento.$emit('alterarTela', name); 
    },
    openmenuprincipal() {
        barramento.$emit('boxMenuprincipal', true);
    }
  },
  mounted() {
    this.$http
      .get('token-title')
      .then(response => {
        this.notificacoescount = response.data.notificacoescount
        this.isNotificacoescount = response.data.isnotificacoescount
        this.isToken = response.data.istoken
        this.isFotousuario = response.data.isfotousuario
        this.fotousuario = response.data.fotousuario
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  
  
}
</script>

<style>
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-menu .btn { padding: 2px 7px; background-color: #FFF; border-color: #FFF; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-menu .btn svg {  height: 15px; color: #FF671B; } 
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #FFF; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 20px; height: 20px; color: #FFF;  }

 #btn-header-notificacoes i { position: absolute; margin-left: -10px;  margin-top: -5px; background-color: #e46805 !important; border-radius: 45%; padding: 3px 4px; color: #FFF; font-size: 7px; vertical-align: text-bottom;}

#box-app-pd-01-title-t .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#box-app-pd-01-title-t .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 svg { width: 45px; height: 45px; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 path { color: #FFF; }
</style>