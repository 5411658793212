<template>
    <div id="app-cadastro">
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">
                    <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/cadastro/icone_1.png"   v-on:click="openpagesvalidar('Dashboard')" />
                </div>
                <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02">
                    <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/cadastro/icone_2.png" />
                </div>
                <div class="box-app-pd-01-info" id="box-app-pd-01-info">
                    <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">Validação E-mail</div>
                    <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">Informe o código para validação.</div>
                </div>
                <div class="box-form-dinamic" id="box-form-dinamic">
                    <div class=" box-form-logo" id="box-form-logo">
                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/cadastro/logo.png" />
                    </div>
                    <div class="box-form-dinamic-00 " v-show="IsReturn" id="box-form-dinamic-login">
                         <form>
                            <div class="mb-5 mt-5">
                                <h3>E-mail validado com  sucesso!</h3> 
                            </div>
                            
                            <button type="button"   v-on:click="openpagesvalidar('Login')" class="btn btn-primary">Fazer Login</button>
                        </form>
                    </div>  
                    <div class="box-form-dinamic-00 " v-show="isLogin" id="box-form-dinamic-login">
                        <form method="post" id="form-validar-cod"  action="javascript:newcadappapcefvalidcodigo()">
                            <div class="mb-5 mt-5">
                                <label for="exampleInputEmail1" class="form-label">Código de validação</label>
                                <input type="text" class="form-control" name="codigo" v-model="email.codigo" id="codigo" required > 
                                <div class="form-text " > 
                                    Caso não tenha encontrado nosso e-mail na Caixa de Entrada, verifique na sua Caixa de Spam.   
                                </div>
                            </div>
                            
                            <button type="button"  v-on:click="validarcodigo()" class="btn btn-primary">Finalizar Cadastro</button>
                        </form>
                    </div>  
                </div>
            </div>
    </div>
</template>

<script>
import barramento from '@/barramento';

export default {
  name: 'ViewResetpasscod', 
  data() {
    return {
      isLogin: true,
      IsReturn: false, 
      email: {
        codigo: '' 
      } 
    };
  },
  methods: {
    openpagesvalidar(name) {
        barramento.$emit('alterarTela', name); 
    },
    validarcodigo() { 
        this.isLoading = true;
        this.$http
        .post('validar-email-pass-cod', {
            codigo: this.email.codigo 
        })
        .then(response => { 
          if(response.data.erro==0){
            barramento.$emit('alterarTela', 'Resetpassnew');
          }else{
            alert('Erro! Tente novamente! '+response.data.erro)
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  },
}
</script>

<style>
#app-cadastro { background-color: #1B3781; }
#app-cadastro .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 30px; }

#app-cadastro .box-app-pd-01-ico-01 { padding: 30px 20px 12px; text-align: right; width: 100%; display: block !important; }
#app-cadastro .box-app-pd-01-ico-01 img { width: 27px; height: 30px; }
#app-cadastro .box-app-pd-01-ico-02 { padding-bottom: 25px; text-align: center; }
#app-cadastro .box-app-pd-01-ico-02 img { width: 100px; height: 98px; }

#app-cadastro .box-app-pd-01-info {  text-align: center; color: #FFF; }
#app-cadastro .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 24px; font-weight: bold; }
#app-cadastro .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 14px; font-weight: bold; padding-bottom: 10px;  }


    
#app-cadastro .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-cadastro .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-cadastro .box-form-dinamic button { width: 100%; background-color: #1B3781; border: 0px;}
#app-cadastro #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-cadastro #form-text-esqueci-senha { width: 100%; text-align: right; }


.sub-buttons-login-app .box-help-00 { width: 82px; height: 82px; padding: 10px; text-align: center; border: 1px solid #1B3781; border-radius: 5px ; }
.sub-buttons-login-app .box-help-00 img { height: 31px ;}
.sub-buttons-login-app .box-help-00 .box-help-00-text  { font-size: 11px; color: #818181}

#form-text-esqueci-senha a{ color:#1B3781 ;}


.display_none { display: none; }
</style>