<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-dashboard">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info">
                        <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">Meu saldo</div>
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                          <div class="feedxt1"> 
                            <div v-show="!isSaldovisualizar">R$ ****** </div>
                            <div v-show="isSaldovisualizar">R$ {{ saldo }} </div>  
                          </div>
                          <div class="feedxt2">
                            <svg xmlns="http://www.w3.org/2000/svg" v-show="!isSaldovisualizar"   v-on:click="opensaldo()" width="25" height="25" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                            </svg>  
                            <svg xmlns="http://www.w3.org/2000/svg" v-show="isSaldovisualizar" width="25" height="25"  v-on:click="closesaldo()" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                            </svg>
                          </div> 
                        </div>
                    </div>
                    <div class="box-form-dinamic" id="box-form-dinamic"> 
                        <div class="box-form-dinamic-00 " id="box-form-dinamic-login">
                            
                              <div class="box-dashboard-00x-00">
                                <div class="box-dashboard-pd-00">
                                  <b-carousel
                                    id="carousel-fade"
                                    style="text-shadow: 0px 0px 2px #000"
                                    fade
                                    indicators
                                    img-width="286"
                                    img-height="146"
                                  >
                                    <b-carousel-slide  v-for="banner in this.banners"  :key="banner.id"
                                      :img-src="banner.foto"
                                    ></b-carousel-slide> 
                                  </b-carousel>
                                </div>
                                <div class="box-dashboard-pd-00">
                                    <div class="box-dashboard-pd-00-title">Classificados </div>
                                    <div class="box-dashboard-pd-00-itens">
                                        <div class="row">
                                            <div class="col-6" v-for="item in this.class"  :key="item.id">
                                                <div class="box-classificados-pd-00" v-on:click="openclassificadodashboard(item.id)">
                                                    <div class="box-classificados-pd-00-img">
                                                        <img :src="item.foto"  />
                                                    </div>
                                                    <div class="box-classificados-pd-00-title">
                                                        {{ item.name }}
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="box-dashboard-pd-00">
                                    <div class="box-dashboard-pd-00-title">Reservas disponiveis</div>
                                    <div class="box-dashboard-pd-00-itens">
                                        <div class="box-reserva-pd-00 " v-for="item in this.reserva"  :key="item.id">
                                            <div class="box-reserva-pd-00-img">
                                                <img  :src="item.foto"  />
                                            </div>
                                            <div class="box-reserva-pd-00-txt">
                                                <div class="box-reserva-pd-00-txt-title">{{ item.name }}</div>
                                                <div class="box-reserva-pd-00-txt-txt">
                                                    {{ item.txtcurto }}
                                                </div>
                                                <div class="box-reserva-pd-00-txt-infos row">
                                                    <div class="box-reserva-pd-00-txt-infos-01 col-6">
                                                        <a href="">saiba mais</a>
                                                    </div>
                                                    <div class="box-reserva-pd-00-txt-infos-02 col-6">
                                                          <i></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-dashboard-pd-00-button">
                                        <button type="button" class="btn btn-primary" id="button-app-reservas"  v-on:click="opentelasdashboard('Reservas')"  >
                                            Ver todas as reservas
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>  
                    </div>
                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewDashboard',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSaldo: false,
      isSaldovisualizar: false,
      saldo: 0,
      token: 0,
      info: '',
      class: {
        id: null,
        name: '',
        foto: ''
      },
      reserva: {
        id: null,
        name: '',
        foto: '',
        txtcurto: ''
      },
      banners: {
        id: null,
        foto: ''
      }
    };
  },
  methods: {
    opentelasdashboard(tela) {
      barramento.$emit('alterarTela', tela); 
    },
    openfazerlogin() {
      barramento.$emit('alterarTela', 'Login'); 
    },
    opensaldo(){
      if(this.token != 0){
        this.isSaldovisualizar = true
      }else{
        barramento.$emit('alterarTela', 'Login'); 
      }
    },
    closesaldo(){
      this.isSaldovisualizar = false
    },
    openclassificadodashboard(id) {
      this.isLoading = true;
      this.$http
          .post('token-classificado-item', { id: id }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
          .then(response => { 
              if(response.data.erro==0){
                  barramento.$emit('alterarTela', 'Classificadositem'); 
              } 
          })
          .catch(error => {
              console.log(error)
              this.errored = true
          })
          .finally(() => this.isLoading = false)
      
    },
  },
  mounted () {
    this.$http
      .get('dashboard')
      .then(response => {
         this.class = response.data.class
         this.reserva = response.data.reserva
         this.banners = response.data.banners  
         this.isSaldo = response.data.issaldo  
         this.saldo = response.data.saldo  
         this.token = response.data.token  
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
  #app-dashboard .box-dashboard-00x-00 {}
  #app-dashboard .box-dashboard-00x-00 .box-dashboard-pd-00 { padding: 5px 0;}
  #app-dashboard .box-dashboard-00x-00 .box-dashboard-pd-00 .box-dashboard-pd-00-title { border-bottom: 1px solid #1B3781; font-size: 16px; font-weight: bold;}
  #app-dashboard .box-dashboard-00x-00 .box-dashboard-pd-00 .box-dashboard-pd-00-itens { padding: 10px 0;}

  #app-dashboard .box-reserva-pd-00 { width: 100%; display: flex; border: 1px solid #D4D3D3; border-radius:8px;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-img { max-width: 121px;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-img img { max-width: 100%; border-radius:8px 0px 0px 8px;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt { padding: 5px;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-title { font-size: 14px; font-weight: bold; }
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-txt { font-size: 12px;  color: #888;  }
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos {}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 { color: #FF671B; font-size: 12px;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 a { color: #FF671B; text-decoration: none;}
  #app-dashboard .box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-02 { color: #1B3781; font-size: 12px; text-align: right;}



  #app-dashboard .box-classificados-pd-00 { width: 100%; max-width: 132px; padding: 0 5px; }
  #app-dashboard .box-classificados-pd-00 .box-classificados-pd-00-img { }
  #app-dashboard .box-classificados-pd-00 .box-classificados-pd-00-img img { max-width: 100%; border-radius:8px;}
  #app-dashboard .box-classificados-pd-00 .box-classificados-pd-00-title { padding: 5px; font-size: 14px; font-weight: bold; }

#app-dashboard .box-form-dinamic-00 { padding-bottom:50px; }

#app-dashboard { background-color: #1B3781; }
#app-dashboard .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 30px; }


#app-dashboard .box-app-pd-01-info {  text-align: center; color: #FFF; }
#app-dashboard .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 27px; padding-bottom: 28px; display: flex; margin: auto; width: 170px; }
#app-dashboard .box-app-pd-01-info .box-app-pd-01-info-title .feedxt11 { width: 48%; text-align: right; padding-right: 2%; }
#app-dashboard .box-app-pd-01-info .box-app-pd-01-info-title .feedxt22 { width: 48%; text-align: left; padding-left: 2%; }
#app-dashboard .box-app-pd-01-info .box-app-pd-01-info-title .feedxt2 {padding-left: 10px; }
#app-dashboard .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }


  
#app-dashboard .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-dashboard .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-dashboard .box-form-dinamic button { width: 100%; background-color: #1B3781; border: 0px;}
#app-dashboard #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-dashboard #form-text-esqueci-senha { width: 100%; text-align: right; }

.box-dashboard-00x-00 {}
.box-dashboard-00x-00 .box-dashboard-pd-00 { padding: 5px 0;}
.box-dashboard-00x-00 .box-dashboard-pd-00 .box-dashboard-pd-00-title { border-bottom: 1px solid #1B3781; font-size: 16px; font-weight: bold;}
.box-dashboard-00x-00 .box-dashboard-pd-00 .box-dashboard-pd-00-itens { padding: 10px 0;}

.box-reserva-pd-00 { width: 100%; display: flex; border: 1px solid #D4D3D3; border-radius:8px;}
.box-reserva-pd-00 .box-reserva-pd-00-img { max-width: 121px;}
.box-reserva-pd-00 .box-reserva-pd-00-img img { max-width: 100%; border-radius:8px 0px 0px 8px; height: 85px;}
.box-reserva-pd-00 .box-reserva-pd-00-txt { padding: 5px;}
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-title { font-size: 14px; font-weight: bold; }
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-txt { font-size: 12px;  color: #888;  }
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos {}
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 { color: #FF671B; font-size: 12px;}
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 a { color: #FF671B; text-decoration: none;}
.box-reserva-pd-00 .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-02 { color: #1B3781; font-size: 12px; text-align: right;}



.box-classificados-pd-00 { width: 100%; }
.box-classificados-pd-00 .box-classificados-pd-00-img { }
.box-classificados-pd-00 .box-classificados-pd-00-img img { max-width: 100%; border-radius:8px; height: 150px;}
.box-classificados-pd-00 .box-classificados-pd-00-title { padding: 5px; font-size: 14px; font-weight: bold; }
</style>