<template>
    <div id="app-notificacoes">
            <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="row" style="margin: 0;">
                    <div class="col-2">
                        <div class="box-app-pd-01-titile-02" >
                            <a class=" btn btn-primary " v-on:click="pagemenufooternotificacao()">
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                              </svg>
                            </a> 
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="box-app-pd-01-titile-01" > 
                                Notificações
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="box-app-pd-01-titile-01" > 
                                
                        </div>
                    </div>
                </div>
                
                    
                <div class="box-form-dinamic" id="box-form-dinamic"> 

                    <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                         <div class="box-noticacoes-list">
                            <div class="box-noticacoes-list-itemx row"  >
                                <div class="box-noticacoes-list-item-title col-12">
                                    <div class="box-noticacoes-list-item-title-pd">
                                        <div class="box-noticacoes-list-item-title-00">{{ item.name }} </div>
                                        <div class="box-noticacoes-list-item-title-01">
                                            {{ item.txt }}
                                        </div>
                                        <div class="box-noticacoes-list-item-title-03"> {{ item.data }} </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>
                      
                </div>
            </div>

    </div>
</template>

<script>

import barramento from "@/barramento";

export default {
  name: 'ViewNotificacoesitem',
  data() {
    return { 
        isLoading: true,
        isMenu: false,
        item: {
            id: null,
            name: '',
            data: '',
            txt: ''
        },
    };
  },
  methods: {
    pagemenufooternotificacao() {
       barramento.$emit('alterarTela', 'Notificacoes'); 
    },
  }, 
  mounted () {
    this.$http
      .get('notificacao-item')
      .then(response => {
        this.item.id = response.data.item.id
        this.item.name = response.data.item.name
        this.item.data = response.data.item.data
        this.item.txt = response.data.item.txt  
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
}
</script>

<style>
.box-noticacoes-list .box-noticacoes-list-itemx { margin: 0 !important; max-height: 100%; }
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-title-pd { padding: 10px; }
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-00 { font-weight: bold; }
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-01 { font-size: 14px;}
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-03 { font-size: 12px; color: #1B3781; padding-top: 10px;  }
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-seta { padding-top: 40px; }
.box-noticacoes-list .box-noticacoes-list-itemx .box-noticacoes-list-item-seta a { color: #ccc; font-size: 22px; }
.box-noticacoes-list .visto-check { background-color: #f1f1f1 !important; }

.box-noticacoes-list .box-noticacoes-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; }
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-title-pd { padding: 10px; }
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-00 { font-weight: bold; }
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-01 { font-size: 14px;}
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-title-pd .box-noticacoes-list-item-title-02 { font-size: 12px; color: #1B3781; }
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-seta { padding-top: 40px; }
.box-noticacoes-list .box-noticacoes-list-item .box-noticacoes-list-item-seta a { color: #ccc; font-size: 22px; }
.box-noticacoes-list .visto-check { background-color: #f1f1f1 !important; }

#app-notificacoes .box-form-dinamic-00 { padding-top: 0px; padding-bottom:50px; }
#app-notificacoes { background-color: #1B3781; }
#app-notificacoes .box-form-dinamic { background-color: #FFF; border-radius: 0px 0px 0 0; padding: 0px 0px 30px 0px; }

#app-notificacoes .box-app-pd-01-titile-01 { padding: 10px 0px 10px 0px; text-align: center; color: #FFF; font-size: 20px;  }  
#app-notificacoes .box-app-pd-01-titile-02 { padding: 10px 0px 10px 0px; text-align: left; } 
#app-notificacoes .box-app-pd-01-titile-02 a { background-color: initial; border: 0;font-size: 20px;} 

    
#app-notificacoes .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-notificacoes .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-notificacoes .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-notificacoes #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-notificacoes #form-text-esqueci-senha { width: 100%; text-align: right; }
</style>