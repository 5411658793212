<template>
  <div id="app">  
    <component :is="componenteativo" ></component> 
    <Footer v-show="isFooter"  />
  </div>
</template>

<script> 
import barramento from '@/barramento'

import Qrcode from './views/Qrcode.vue'
import Classificados from './views/Classificados.vue'
import Classificadosanunciar from './views/Classificadosanunciar.vue'
import Classificadosanunciarfotos from './views/Classificadosanunciarfotos.vue'
import Classificadosmeusanuncios from './views/Classificadosmeusanuncios.vue'
import Convites from './views/Convites.vue'
import Convitesitem from './views/Convitesitem.vue'
import Dashboard from './views/Dashboard.vue'
import Indiqueganhe from './views/Indiqueganhe.vue'
import Login from './views/Login.vue'
import Menu from './views/Menu.vue'
import Meucadastro from './views/Meucadastro.vue'
import Notificacoes from './views/Notificacoes.vue'
import Notificacoesitem from './views/Notificacoesitem.vue'
import Reservas from './views/Reservas.vue'
import Reservasitem from './views/Reservasitem.vue'
import Reservashistorico from './views/Reservashistorico.vue'
import Sorteios from './views/Sorteios.vue'
import Cadastro from './views/Cadastro.vue'
import Validacaoemail from './views/Validacaoemail.vue'
import Sorteiositem from './views/Sorteiositem.vue'
import Classificadositem from './views/Classificadositem.vue'
import Resetpass from './views/Resetpass.vue'
import Resetpasscod from './views/Resetpasscod.vue'
import Resetpassnew from './views/Resetpassnew.vue'


import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: { 
    Qrcode, 
    Classificados, 
    Classificadosanunciar,
    Classificadosanunciarfotos,
    Classificadosmeusanuncios,
    Convites,  
    Convitesitem, 
    Dashboard, 
    Indiqueganhe, 
    Login, 
    Menu, 
    Meucadastro, 
    Notificacoes, 
    Notificacoesitem, 
    Sorteios,
    Reservas,
    Reservasitem,
    Reservashistorico,
    Cadastro,
    Validacaoemail,
    Footer,
    Sorteiositem,
    Classificadositem,
    Resetpass,
    Resetpasscod,
    Resetpassnew
  },
  data() {
    return {
      componenteativo : 'Dashboard',
      tela: 'Dashboard',
      toke: '',
      isFooter: true
    };
  },
  created(){
    barramento.$on('alterarTela', name =>{

      switch (name) {

        case 'Notificacoes':
          this.componenteativo = name
          this.isFooter = false
          break;

        case 'Notificacoesitem':
          this.componenteativo = name
          this.isFooter = false
          break;
      
        case 'Meucadastro':
          if (this.toke!='') {
            this.isFooter = true
            this.componenteativo = name
          }else{ this.componenteativo = 'Meucadastro'; this.isFooter = true }
          
          break;

        
        case 'Qrcode':
          if (this.toke!='') {
            this.componenteativo = name
          }else{ this.componenteativo = 'Qrcode' }
          this.isFooter = false
          break;

        
        case 'Convites':
          if (this.toke!='') {
            this.isFooter = true
            this.componenteativo = name
          }else{ this.componenteativo = 'Convites'; this.isFooter = true }
          
          break;

        case 'Login':
          this.componenteativo = name
          this.isFooter = false
          
          break;

        default: 
          this.componenteativo = name
          this.isFooter = true
          break;
      }
       
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  margin: 0px;
}
</style>
