<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-sorteios">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            Sorteios
                            <img src="../assets/img/icones-f-azul/3.png" style="width:30px; height: 30px;vertical-align: sub;" />
                        </div>
                    </div>
                    <div class="box-form-dinamic" id="box-form-dinamic">
                         
                        <div v-show="isSorteiosativos" class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                            
                            <div v-show="isSorteiosp">
                                <div class="box-sorteios-list2">
                                    <div class="box-sorteios-list-item2 "   >
                                        <div class="box-sorteios-list-item-title ">
                                            <div class="box-sorteios-list-item-title-pd">
                                                <div class="box-sorteios-list-item-title-00">Em destaque</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-sorteios-list">
                                    <div  v-for="item in sorteiosp" :key="item.id" class="box-sorteios-list-item row" v-on:click="opensorteio(item.id)" >
                                        <div class="box-sorteios-list-item-img col-4">
                                            <img :src="item.foto"  />
                                        </div>
                                        <div class="box-sorteios-list-item-title col-8">
                                            <div class="box-sorteios-list-item-title-pd">
                                                <div class="box-sorteios-list-item-title-00">{{ item.name }} </div>
                                                <div class="box-sorteios-list-item-title-01">{{ item.txtcurto }}</div>
                                                <div class="box-sorteios-list-item-title-02"><b>Data:</b> {{ item.datasorteio }}</div>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                            
                            <div v-show="isSorteios">
                                <div class="box-sorteios-list3">
                                    <div class="box-sorteios-list-item2 "   >
                                        <div class="box-sorteios-list-item-title ">
                                            <div class="box-sorteios-list-item-title-pd">
                                                <div class="box-sorteios-list-item-title-00">Recentes</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-sorteios-list">
                                    <div  v-for="item in sorteios" :key="item.id" class="box-sorteios-list-item row" v-on:click="opensorteio(item.id)" >
                                    
                                        <div class="box-sorteios-list-item-img col-4">
                                            <img :src="item.foto"  />
                                        </div>
                                        <div class="box-sorteios-list-item-title col-8">
                                            <div class="box-sorteios-list-item-title-pd">
                                                <div class="box-sorteios-list-item-title-00">{{ item.name }} </div>
                                                <div class="box-sorteios-list-item-title-01">{{ item.txtcurto }}</div>
                                                <div class="box-sorteios-list-item-title-02"><b>Data:</b> {{ item.datasorteio }}</div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            
                        </div>
                        <div v-show="!isSorteiosativos" class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                             
                            <div class="box-sorteios-list">
                                <div class="box-sorteios-list-item row"  > 
                                    <div class="box-sorteios-list-item-title col-12">
                                        <div class="box-sorteios-list-item-title-pd">
                                            <div class="box-sorteios-list-item-title-00">
                                                No momento nenhum sorteio foi cadastrado.    
                                            </div> 
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>

                          
                    </div>
                </div>

        </section>

        
    </div>
</template>

 

<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewSorteios',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSorteios: false,
      isSorteiosp: false,
      isSorteiosativos: false,
      info: '',
      sorteios: {
        id: null,
        name: '',
        foto: '',
        txtcurto: '',
        datasorteio: ''
      },
      sorteiosp: {
        id: null,
        name: '',
        foto: '',
        txtcurto: '',
        datasorteio: ''
      }
    };
  },
  methods: {
    opensorteio(id) {
        
      this.isLoading = true;
      this.$http
          .post('token-sorteios-item', { id: id }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
          .then(response => { 
              if(response.data.erro==0){
                  barramento.$emit('alterarTela', 'Sorteiositem'); 
              } 
          })
          .catch(error => {
              console.log(error)
              this.errored = true
          })
          .finally(() => this.isLoading = false)

    },
  },
  mounted () {
    this.$http
      .get('token-sorteios')
      .then(response => {
        this.sorteios = response.data.sorteios
        this.sorteiosp = response.data.sorteiosp
        this.isSorteios = response.data.issorteios
        this.isSorteiosp = response.data.issorteiospremium
        this.isSorteiosativos = response.data.issorteiosativos
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
#app-sorteios .sub-me-pd-01-ative { color: #FF671B !important; }
#app-sorteios .sub-me-pd-01-ative path { color: #FF671B !important; }

#app-sorteios .box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

#app-sorteios { background-color: #1B3781; }
#app-sorteios .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 25Wpx; }

#app-sorteios .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#app-sorteios .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#app-sorteios .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#app-sorteios .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#app-sorteios .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

#app-sorteios .box-app-pd-01-info {  text-align: center; color: #FFF; padding-top: 15px; }
#app-sorteios .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 22px; padding-bottom: 10px; padding-right: 28px; text-align: right; }
#app-sorteios .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }


  
#app-sorteios .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-sorteios .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-sorteios .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-sorteios #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-sorteios #form-text-esqueci-senha { width: 100%; text-align: right; }

#app-sorteios .box-app-pd-01-ico-01 #btn-header-menu .btn { padding: 2px 7px; background-color: #FFF; border-color: #FFF; }
#app-sorteios .box-app-pd-01-ico-01 #btn-header-menu .btn svg {  height: 15px; color: #FF671B; } 
#app-sorteios .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#app-sorteios .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #FFF; }
#app-sorteios .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; }
#app-sorteios .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 25px; height: 25px; color: #FFF;  }


#app-sorteios .box-sorteios-list { padding: 10px 25px;  }
#app-sorteios .box-sorteios-list .box-sorteios-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; padding-top: 10px; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title { padding-left: 0 !important; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd { padding: 10px 0; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-01 { font-size: 14px;}
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-02 { font-size: 12px; color: #1B3781; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-seta { padding-top: 40px; }
#app-sorteios .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-seta a { color: #ccc; font-size: 22px; }
#app-sorteios .box-sorteios-list .visto-check { background-color: #f1f1f1 !important; }

#app-sorteios .box-sorteios-list-item-img img { height: 70px; border-radius: 50%; max-width: 100%; }

#app-sorteios .box-sorteios-list2 { padding: 5px 25px 0; }
#app-sorteios .box-sorteios-list2 .box-sorteios-list-item2 { margin: 0 !important;    }
#app-sorteios .box-sorteios-list2 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd { padding: 10px 0 0px; }
#app-sorteios .box-sorteios-list2 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; color:#e46805; }

#app-sorteios .box-sorteios-list3 { padding: 15px 25px 0; }
#app-sorteios .box-sorteios-list3 .box-sorteios-list-item2 { margin: 0 !important;    }
#app-sorteios .box-sorteios-list3 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd { padding: 10px 0 0; }
#app-sorteios .box-sorteios-list3 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; color:#1B3781 }

</style>