<template>
    <div>
        
    </div>
</template>


<script>
export default {
  name: 'ViewMenu' 
}
</script>

<style>

</style>