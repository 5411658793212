<template>
    <div id="app-qrcode">
            <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="row" style="margin: 0;">
                    <div class="col-2">
                        <div class="box-app-pd-01-titile-02" >
                            <a class=" btn btn-primary " v-on:click="pagemenufooterqrcode()">
                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                             </svg>
                            </a> 
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="box-app-pd-01-titile-01" > 
                                Qrcode
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="box-app-pd-01-titile-01" > 
                                
                        </div>
                    </div>
                </div>
                
                    
                <div class="box-form-dinamic" id="box-form-dinamic"> 

                    <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                        <div class="box-noticacoes-list" id="box-qrcode">
                             
                        </div>
                    </div>
                      
                </div>
            </div>

    </div>
</template>

<script>

import barramento from "@/barramento";

export default {
  name: 'ViewQrcode',
  methods: {
    pagemenufooterqrcode() {
       barramento.$emit('alterarTela', 'Dashboard'); 
    } 
  },
  data() {
    return { 
        isLoading: true,
        isMenu: false,
        qrcode: '',
    };
  },
  mounted() { 
    this.$http
      .post('token-qrcode-item')
      .then(response => {
        if (response.data.erro==0) {
            document.getElementById("box-qrcode").innerHTML = response.data.qrcode
        }else{
          barramento.$emit('alterarTela', 'Login'); 
        } 
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  
}
</script>

<style> 
#app-qrcode .box-form-dinamic-00 { padding-top: 0px; padding-bottom:50px; }
#app-qrcode { background-color: #1B3781; }
#app-qrcode .box-form-dinamic { background-color: #FFF; border-radius: 0px 0px 0 0; padding: 0px 0px 30px 0px; }

#app-qrcode .box-app-pd-01-titile-01 { padding: 10px 0px 10px 0px; text-align: center; color: #FFF; font-size: 20px;  }  
#app-qrcode .box-app-pd-01-titile-02 { padding: 10px 0px 10px 0px; text-align: left; } 
#app-qrcode .box-app-pd-01-titile-02 a { background-color: initial; border: 0;font-size: 20px;} 

    
#app-qrcode .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-qrcode .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-qrcode .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-qrcode #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-qrcode #form-text-esqueci-senha { width: 100%; text-align: right; }
</style>