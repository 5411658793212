<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-classificadosy">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            Reservas
                            <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/icones-f-azul/4.png" style="width:30px; height: 30px;vertical-align: sub;" />
                        </div>
                    </div>
                    <div class="box-form-dinamic" id="box-form-dinamic">
                         
                        <div class="sub-menu-pd-01">
                             
                          <div class="sub-me-pd-01-item " id="bt-voltar-reservas" v-on:click="voltarreservas()">
                              <div class="sub-me-pd-01-item-ico">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                  </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Voltar
                              </div>
                          </div>
                          <div class="sub-me-pd-01-item sub-me-pd-01-ative" v-on:click="voltarreservas()">
                              <div class="sub-me-pd-01-item-ico">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                      <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                  </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Listar
                              </div>
                          </div>
                          <div class="sub-me-pd-01-item" v-on:click="openhistoricoreservas()">
                              <div class="sub-me-pd-01-item-ico">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                      <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                  </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Histórico
                              </div>
                          </div>
                        </div>
                        <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                            
                            <div class="row">

                                 <div class="box-reserva-pd-00xyt " > 
                                    <div class="box-reserva-pd-00-txt">
                                        <div class="box-reserva-pd-00-txt-title">  Unidade: {{ unidade }}  </div>
                                        <div class="box-reserva-pd-00-txt-title pb-3">Churrasqueira 01 </div>
                                        <div class="box-reserva-pd-00-txt-txt">
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                            Lorem ipsum dolor  sit amet, um dolor 
                                        </div> 
                                    </div>

                                    <div class="box-reserva-pd-00-img">
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/dashboard/02.jpg"  />
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/dashboard/02.jpg"  />
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/dashboard/02.jpg"  />
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/dashboard/02.jpg"  />
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/dashboard/02.jpg"  />
                                        <div class="clear"></div>
                                    </div> 
                                    <hr>
                                    <form method="post" id="form-validar-dados-reservar"  action="javascript:newcadappapcefvaliddados()">
                                        <div class="box-form-reservar"  id="box-form-reservar-01">
                                            <div class="mb-4">
                                                <div for="exampleInputEmail1" class="form-label">Data </div>
                                                <button type="button" v-on:click.prevent="fazerreserva()" class="btn btn-primary">Selecione a data disponível</button> 
                                            </div>
                                            <div class="mb-4">
                                                <label for="exampleInputEmail1" class="form-label">Assinatura digital </label>
                                                <input type="password" class="form-control" id="assinatura" name="assinatura"   required> 
                                            </div>  
                                        </div>

                                        <div class="box-bt-fazer-reserva pb-3 pt-3">
                                            <button type="button" v-on:click.prevent="fazerreserva()" class="btn btn-primary">Reservar</button>
                                        </div>

                                    </form>

                                </div>

                            </div>
                        </div>
                          
                    </div>
                </div>

        </section>

        
    </div>
</template>

 

<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewReservasitem',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      info: '',
      unidade: 'Goiânia'
    };
  },
  methods: { 
    voltarreservas() {
      barramento.$emit('alterarTela', 'Reservas'); 
    },
    openhistoricoreservas() {
      barramento.$emit('alterarTela', 'Reservashistorico'); 
    },
  },
  mounted () {
    this.$http
      .get('products')
      .then(response => {
        this.info = response.data.products[0].title
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
.sub-menu-pd-01 { width: 100%; display: flex;; margin: auto; height: 70px; border-radius: 8px; box-shadow:1px 1px 5px #DEDEDE; background-color: #FFF; padding: 10px; margin-top: -60px;}
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico { height: 30px; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico path { color: #1B3781; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-txt { font-size: 13px; }
.sub-menu-pd-01 .sub-me-pd-01-item  { width: 70px; text-align: center; }

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }

.box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

.box-classificado-00-pd { width: 100%; border: 1px solid #f3f3f3; border-radius:8px; }
.box-classificado-00-pd .box-classificado-00-pd-img { }
.box-classificado-00-pd .box-classificado-00-pd-info { display: flex; }
.box-classificado-00-pd .box-classificado-00-pd-img img { max-width: 100%; border-radius:8px 8px 0 0;}
.box-classificado-00-pd .box-classificado-00-pd-info-title { padding: 5px; font-size: 12px; font-weight: bold; }
.box-classificado-00-pd .box-classificado-00-pd-info-valor { padding: 5px; font-size: 12px; color:#1b3781; }

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }

.box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

#app-classificadosy { background-color: #1B3781; }
#app-classificadosy .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 30px; }

#app-classificadosy .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#app-classificadosy .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#app-classificadosy .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#app-classificadosy .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#app-classificadosy .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

#app-classificadosy .box-app-pd-01-info {  text-align: center; color: #FFF; padding-top: 15px; }
#app-classificadosy .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 22px; padding-bottom: 50px; padding-right: 28px; text-align: right; }
#app-classificadosy .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }


  
#app-classificadosy .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-classificadosy .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-classificadosy .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-classificadosy #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-classificadosy #form-text-esqueci-senha { width: 100%; text-align: right; }

#app-classificadosy .box-app-pd-01-ico-01 #btn-header-menu .btn { padding: 2px 7px; background-color: #FFF; border-color: #FFF; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-menu .btn svg {  height: 15px; color: #FF671B; } 
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #FFF; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 25px; height: 25px; color: #FFF;  }



.box-reserva-pd-00xyt { width: 100% !important; display: block;   padding: 0 !important;}
.box-reserva-pd-00xyt .box-reserva-pd-00-img { width: 100%; display: block; padding: 10px 0; }
.box-reserva-pd-00xyt .box-reserva-pd-00-img img { max-width: 95px; border-radius:10px; padding: 5px; float: left;}
.box-reserva-pd-00xyt .box-reserva-pd-00-txt {   width: 100%;   }
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-title { font-size: 14px; font-weight: bold; }
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-txt { font-size: 12px;  color: #888;  }
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos {}
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 { color: #FF671B; font-size: 12px;}
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-01 a { color: #FF671B; text-decoration: none;}
.box-reserva-pd-00xyt .box-reserva-pd-00-txt .box-reserva-pd-00-txt-infos .box-reserva-pd-00-txt-infos-02 { color: #1B3781; font-size: 12px; text-align: right;}


.clear { clear: both !important;}
.box-bt-fazer-reserva button { background-color: #FF671B; border: 0; width: 100%;}
</style>