<template>
    <div id="app-menu-principal">
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="row"> 
                    <div class="col-12">
                        <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">  
                            <div id="btn-header-menu-close" v-on:click="closemenuprincipal()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>  
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="row" id="box-dados-user">
                    <div class="col-3">
                        <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02">
                            <img v-show="!isFotousuario" src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/login/icone_1.png" v-on:click="openmenuprincipal('Meucadastro')" />
                            <b-avatar variant="info" :src="'https://placekitten.com/300/300'"  v-show="isFotousuario"   v-on:click="openmenuprincipal('Meucadastro')" size="4rem"></b-avatar>

                        </div>
                    </div>
                    <div class="col-9"> 
                            <div class="box-app-pd-01-info" id="box-app-pd-01-info" v-on:click="openmenuprincipal('Meucadastro')">
                                <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">{{ nomeusuario }}</div>
                                <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">{{ emailusuario }}</div>
                            </div>
                    </div> 
                </div>
                
                
                
                <div class="box-form-dinamic" id="box-form-dinamic">
                        
                    <div class="box-form-dinamic-00 box-menu-principal " id="box-menu-principal-00" >
                         

                        <div  v-for="item in menua" :key="item.id" class="row box-menu-principal-x0 " v-on:click="openmenuprincipal(item.val)"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <img :src="item.img" />
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >{{ item.textmenu }}</div> 
                                </div>
                            </div> 
                        </div> 
                        <div  v-for="item in menud" :key="item.id"  class="row box-menu-principal-x0 desabilitados" v-on:click="openmenuprincipal(item.val)"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <img :src="item.img" />
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >{{ item.textmenu }}</div> 
                                </div>
                            </div> 
                        </div>
                         
                        <div class="box-menu-principal-sessao" id="box-menu-principal-sessao-00">
                            <hr>
                            
                            <div class="row box-menu-principal-x0" v-show="!isToken"   v-on:click="openmenuprincipal('Login')" >
                                <div class="col-2">
                                    <div class="box-menu-principal-img"  >
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/open-session.png" />
                                    </div>
                                </div>
                                <div class="col-10"> 
                                    <div class="box-menu-principal-title "  >
                                        <div >Fazer Login</div> 
                                    </div>
                                </div> 
                            </div>
                            
                            <div class="row box-menu-principal-x0" v-show="isToken"   v-on:click="closesessionapp()" >
                                <div class="col-2">
                                    <div class="box-menu-principal-img"  >
                                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/close-session.png" />
                                    </div>
                                </div>
                                <div class="col-10"> 
                                    <div class="box-menu-principal-title "  >
                                        <div >Sair</div> 
                                    </div>
                                </div> 
                            </div>
                        </div>

                    </div>
                        
                        
                </div>
            </div>

    </div>
</template>

<script>
import barramento from "@/barramento";
export default {
    name: 'ViewMenuprincipal', 
    data: function() {
        return { 
            fotousuario: '',
            isLoading: false,
            isToken: false,
            isFotousuario: false,
            nomeusuario: 'Login',
            emailusuario: 'clique aqui e faça seu login.',
            menua: {},
            menud: {}
        }
    },
    methods: {
        closemenuprincipal() {
            barramento.$emit('boxMenuprincipal', false);
        },
        openmenuprincipal(name) {
            barramento.$emit('alterarTela', name);
        },
        closesessionapp(){
            this.$http
            .get('token-close-session-app')
            .then(response => {  
                if(response.data.erro==0){
                    barramento.$emit('alterarTela', 'Dashboard'); 
                } 
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.isLoading = false)
        }
    },
    mounted() {
        this.$http
        .get('token-menu')
        .then(response => { 
            this.isToken = response.data.istoken
            this.isFotousuario = response.data.isfotousuario
            this.fotousuario = response.data.fotousuario
            this.nomeusuario = response.data.nomeusuario
            this.emailusuario = response.data.emailusuario
            this.menua = response.data.menua
            this.menud = response.data.menud
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
}
</script>

 
<style> 
    #app-menu-principal { background-color: #1B3781; position: fixed; z-index: 9999; top: 0; left: 0; bottom: 0; right: 0;   }
    #app-menu-principal .box-form-dinamic { background-color: #FFF; border-radius: 0px 20px 0 0; padding: 30px; }

    #app-menu-principal .box-app-pd-01-ico-01 { padding: 15px 8px 0px 0px; width: 100%; text-align: right;}  
    #app-menu-principal .box-app-pd-01-ico-02 { padding: 0px; text-align: right; }
    #app-menu-principal .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

    #app-menu-principal .box-app-pd-01-info {  text-align: left; color: #FFF; }
    #app-menu-principal .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 16px; padding-bottom: 0px; }
    #app-menu-principal .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 14px; font-weight: bold; padding-bottom: 0px;  }

    
        
    #app-menu-principal .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
    #app-menu-principal .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
    #app-menu-principal .box-form-dinamic button { width: 100%; background-color: #1B3781; border: 0px;}
    #app-menu-principal #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

    #app-menu-principal #box-dados-user  { padding-bottom: 20px; }
    #app-menu-principal .box-app-pd-01-ico-01 #btn-header-menu-close  svg {  color: #FFF; }  


    #app-menu-principal .box-menu-principal .desabilitados {  color: #D4D4D4 !important; }
    
    #app-menu-principal .box-menu-principal {  text-align: left; color: #5E5A5A; }
    #app-menu-principal .box-menu-principal .box-menu-principal-x0 { padding: 10px 0; }
    #app-menu-principal .box-menu-principal .box-menu-principal-img img { max-width: 23px; } 
    #app-menu-principal .box-menu-principal .box-menu-principal-title { font-size: 16px; padding-bottom: 0px; } 

    
    #app-menu-principal .menu-ative-00 {  color: #e76904 !important; }

    .box-menu-principal-sessao { background: #FFF;   width: 100%;  height: 65px; z-index: 99;  bottom: 20px;   }

</style>