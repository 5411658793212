<template> 
    <div id="box-menu-footer" >
        <div class="menu-footer" >
            <div id="menu-footer" >
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-4">
                    <a class="    footer-menu-item-xd-00x" v-on:click="pagemenufooter(4, 'Convites')">
                        <div class="footer-menu-item-xd-00x-imgx" >
                            <img src="../assets/img/icones-f-azul/4.png" style="width: 20px; height: 25px;" />
                        </div>
                        <span>Emitir Convites</span>
                    </a>
                </div> 
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-3">
                    <a class="    footer-menu-item-xd-00x" v-on:click="pagemenufooter(3, 'Sorteios')">
                        <div class="footer-menu-item-xd-00x-imgx" >
                            <img src="../assets/img/icones-f-azul/3.png" style="width: 25px; height: 25px;" />
                        </div>
                        <span>Sorteios</span>
                    </a>
                </div> 
                <div class="menu-footer-item ative-footer-menu" id="footer-menu-item-xd-00x-1">
                    <a class="   footer-menu-item-xd-00x " v-on:click="pagemenufooter(1, 'Dashboard')">
                    <div class="footer-menu-item-xd-00x-imgx" >
                        <img src="../assets/img/icones-f-azul/1.png" style="width: 18px; height: 23px;" />
                        </div>
                        <span>Home</span>
                    </a>
                </div> 
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-5">
                    <a class="   footer-menu-item-xd-00x" v-on:click="pagemenufooter(5, 'Classificados')">
                    <div class="footer-menu-item-xd-00x-imgx" >
                        <img src="../assets/img/icones-f-azul/6.png" style="width: 25px; height: 23px;" />
                        </div>
                        <span>Classificados</span>
                    </a>
                </div> 
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-7">
                    <a class="   footer-menu-item-xd-00x " v-on:click="pagemenufooter(7, 'Indiqueganhe')">
                    <div class="footer-menu-item-xd-00x-imgx" >
                        <img src="../assets/img/icones-f-azul/7.png" style="width: 23px; height: 23px;" />
                        </div>
                        <span>Ganhe</span>
                    </a>
                </div>  
            
            </div>
        </div>
    </div>  
</template>


<script>
import barramento from '@/barramento'

export default {
  name: 'AppFooter',
  props: ['componenteativo'],
  data: function() {
    return {
        idant: 1
    }
  },
  methods: {
    pagemenufooter(id, name){ 
       // document.getElementById('footer-menu-item-xd-00x-1').removeClass('ative-footer-menu');
        
        var element2 = document.getElementById('footer-menu-item-xd-00x-'+this.idant);
        element2.classList.remove("ative-footer-menu");

        var element = document.getElementById('footer-menu-item-xd-00x-'+id);
        element.classList.add("ative-footer-menu");

        this.idant = id;

        barramento.$emit('alterarTela', name); 
    
    }
  },
  created() {
    barramento.$on('pagemenupdfooter', id =>{

        var element2 = document.getElementById('footer-menu-item-xd-00x-'+this.idant);
        element2.classList.remove("ative-footer-menu");

        var element = document.getElementById('footer-menu-item-xd-00x-'+id);
        element.classList.add("ative-footer-menu");
        this.idant = id;

    })
  },
  
  
}
</script>

<style>
    #box-menu-footer { background-color: #1B3781; position: fixed; width: 100%;  height: 55px; padding: 5px 8px 0;  z-index: 99;  bottom: 0; left: 0; border-radius: 5px 5px 0 0;}
    #box-menu-footer .menu-footer {  width: 100%;  }
    #box-menu-footer .menu-footer #menu-footer {  width: 100%; display: flex;  }
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item { width: 20%; text-align: center; border-radius: 5px;}
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item a {  text-decoration: none; }
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item span { font-size: 9px; color: #FFF; }
    .footer-menu-item-xd-00x-imgx { height: 25px; padding-top: 3px;}
    .ative-footer-menu { background-color: #e46805 !important; }
</style>