<template>
    <div id="app-cadastro">
            <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">
                    <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/cadastro/icone_1.png"   v-on:click="openpageslogin('Dashboard')" />
                </div>
                <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02">
                    <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/login/icone_1.png" />
                </div>
                <div class="box-app-pd-01-info" id="box-app-pd-01-info">
                    <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">Login</div>
                    <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">Informe seu e-mail e sua senha.</div>
                </div>
                <div class="box-form-dinamic" id="box-form-dinamic">
                    <div class=" box-form-logo" id="box-form-logo">
                        <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/cadastro/logo.png" />
                    </div>
                    <div class="box-form-dinamic-00 " v-show="IsReturn" id="box-form-dinamic-login">
                         {{ resposta }}
                    </div>  
                    <div class="box-form-dinamic-00 " v-show="isLogin" id="box-form-dinamic-login">
                         <form> 
                            <div class="mb-4">
                                <label for="email" class="form-label">E-mail</label>
                                <input type="email" name="email" id="email" v-model="usuario.email" class="form-control"  > 
                            </div>
                            <div class="mb-4">
                                <label for="exampleInputPassword1" class="form-label">Senha</label>
                                <input type="password" class="form-control" id="pass" v-model="usuario.pass" name="pass" >
                                <div class="form-text " id="form-text-esqueci-senha"> 
                                    <a href="javascript:void(0)" v-on:click="openpageslogin('Resetpass')">Esqueci minha senha</a>    
                                </div>
                            </div>  
                            <button type="button" class="btn btn-primary" v-on:click="logar()" id="button-app-entrar"  >Entrar</button>
                            <hr>
                            <button type="button" class="btn btn-primary"  v-on:click="openpageslogin('Cadastro')" id="button-app-cadastrar">Cadastrar</button>

                            <div class="mb-4 mt-4 sub-buttons-login-app">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="box-help-00">
                                            <div class="box-help-00-ico">
                                                <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/login/icone_4.png" />
                                            </div>
                                            <div class="box-help-00-text"> 
                                                <div>
                                                    Preciso
                                                </div>
                                                <div>de Ajuda</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="box-help-00">
                                            <div class="box-help-00-ico">
                                                <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/mobile/login/icone_3.png" />
                                            </div>
                                            <div class="box-help-00-text">
                                                <div>
                                                    Telefones
                                                </div>
                                                <div>CAIXA</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="box-help-00">
                                            <div class="box-help-00-ico">
                                                <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/icones-f-branco/4.png" style="width: 23px; height: 29px;" />
                                            </div>
                                            <div class="box-help-00-text">
                                                <div>
                                                    Meus
                                                </div>
                                                <div>Benéficios</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 

                        </form>
                    </div>  
                </div>
            </div>
    </div>
</template>

<script>
import barramento from '@/barramento';
import Loading from '../components/Loading.vue'

export default {
  name: 'ViewLogin', 
  components:{ 
    Loading
  },
  data() {
    return {
      isLogin: true,
      IsReturn: false, 
      isLoading: false,
      resposta: '',
      usuario: {
        email: '',
        pass: ''
      }
    };
  },
  methods: {
    openpageslogin(name) {
      barramento.$emit('alterarTela', name); 
    },
    logar(){
        
      this.isLoading = true;
      this.$http
        .post('sessao', {
            pass: this.usuario.pass,
            email: this.usuario.email 
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
          if(response.data.erro==0){
            barramento.$emit('alterarTela', 'Dashboard'); 
          }else{
            alert('Erro! Tente novamente! '+response.data.erro)
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  },
}
</script>

<style>
#app-cadastro { background-color: #1B3781; }
#app-cadastro .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 30px; }

#app-cadastro .box-app-pd-01-ico-01 { padding: 30px 20px 12px; text-align: right; width: 100%; display: block !important; }
#app-cadastro .box-app-pd-01-ico-01 img { width: 27px; height: 30px; }
#app-cadastro .box-app-pd-01-ico-02 { padding-bottom: 25px; text-align: center; }
#app-cadastro .box-app-pd-01-ico-02 img { width: 100px; height: 98px; }

#app-cadastro .box-app-pd-01-info {  text-align: center; color: #FFF; }
#app-cadastro .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 24px; font-weight: bold; }
#app-cadastro .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 14px; font-weight: bold; padding-bottom: 10px;  }


    
#app-cadastro .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-cadastro .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-cadastro .box-form-dinamic button { width: 100%; background-color: #1B3781; border: 0px;}
#app-cadastro #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-cadastro #form-text-esqueci-senha { width: 100%; text-align: right; }


.sub-buttons-login-app .box-help-00 { width: 82px; height: 82px; padding: 10px; text-align: center; border: 1px solid #1B3781; border-radius: 5px ; }
.sub-buttons-login-app .box-help-00 img { height: 31px ;}
.sub-buttons-login-app .box-help-00 .box-help-00-text  { font-size: 11px; color: #818181}

#form-text-esqueci-senha a{ color:#1B3781 ;}


.display_none { display: none; }
</style>