<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-sorteios-item">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            Sorteios
                            <img src="../assets/img/icones-f-azul/3.png" style="width:30px; height: 30px;vertical-align: sub;" />
                        </div>
                    </div>
                    <div class="box-form-dinamic" id="box-form-dinamic">
                         
                        <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                             
                             
                            <div class="box-sorteio-list">
                                <div class="box-sorteio-list-itemx row"  >
                                    <div class="box-sorteio-list-item-title col-12">
                                        <div class="box-sorteio-list-item-title-pd">
                                            <div class="box-sorteio-list-item-title-00"> {{ item.name }} </div>
                                            <div class="box-sorteio-list-item-title-01"><b>Data do sorteio:</b> {{ item.datasorteio }}</div>
                                            
                                            <div class="box-classificado-00-pd-img">
                                                <img :src="item.foto"  />
                                            </div>
                                            <div class="box-sorteio-list-item-title-01">
                                                <div><b>Premiação:</b></div>
                                                <div>
                                                    {{ item.txt }} 
                                                </div>
                                            </div>
                                            <div v-show="isReturn">
                                                <div class="box-sorteio-list-item-title-00"> {{ isReturntxt }} </div>
                                            </div>
                                            <div v-show="!isReturn">
                                                <div v-show="isToken">
                                                    <div class="box-sorteio-list-item-title-03" v-show="!isFormSorteio" id="box-button-participar-sorteio-01">
                                                        <a class=" btn btn-primary " v-on:click="participarsorteiotoken(item.id)">Participar do sorteio</a>   
                                                    </div>
                                                </div>
                                                <div v-show="!isToken">
                                                    <div class="box-sorteio-list-item-title-03" v-show="!isFormSorteio" id="box-button-participar-sorteio-01">
                                                        <a class=" btn btn-primary " v-on:click="openformsorteio()">Participar do sorteio</a>   
                                                    </div>
                                                    <div class="box-form-participar-sorteio" v-show="isFormSorteio" id="box-form-participar-sorteio-01">
                                                        <form method="post" id="form-validar-dados-sorteio"  action="javascript:newcadappapcefvaliddados()">
                                                            <div class="mb-4">
                                                                <label for="exampleInputEmail1" class="form-label">Nome</label>
                                                                <input type="text" class="form-control" v-model="participar.name" id="name" name="name" placeholder="Nome Completo" required> 
                                                            </div>
                                                            <div class="mb-4">
                                                                <label for="exampleInputEmail1" class="form-label">Email </label>
                                                                <input type="email" class="form-control" v-model="participar.email" id="email" name="email" placeholder="Meu melhor e-mail" required> 
                                                            </div>
                                                            <div class="mb-4">
                                                                <label for="exampleInputPassword1" class="form-label">Telefone</label>
                                                                <input type="text" class="form-control" v-model="participar.celular" id="celular" name="celular" placeholder="Preferencialmente o whatsapp"   required>
                                                            </div>
                                                            <div class="mb-4 form-check">
                                                                <input type="checkbox" class="form-check-input" id="validlgpd" required>
                                                                <label class="form-check-label" for="validlgpd" style="font-size:12px" >Aceito as politicas de privacidade <a href="" style="color:#1B3781 ;">saiba mais</a></label>
                                                            </div>
                                                            <button type="submit" v-on:click.prevent="participarsorteio(item.id)" class="btn btn-primary">Confirmar</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                             
                        </div>
                          
                    </div>
                </div>

        </section>

        
    </div>
</template>

 

<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewSorteiositem',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isFormSorteio: false, 
      isToken: false, 
      isReturn: false, 
      isReturntxt: '', 
      item: {
        id: null,
        name: '',
        foto: '',
        datasorteio: '',
        txtcurto: ''   ,
        txt: ''        
      },
      participar: {
        name: '',
        email: '',
        celular: ''
      }
    };
  },
  methods: {
    openformsorteio() {
        this.isFormSorteio = true
    },
    participarsorteiotoken(id) {
        this.$http
        .post('token-participar-sorteio', {
            id: id, 
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => { 
            if(response.data.erro==0){
                this.isReturn = response.data.isreturn
                this.isReturntxt = response.data.isreturntxt
            } 
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    participarsorteio(id){
        this.$http
        .post('participar-sorteio-s-token', {
            id: id, 
            name: this.participar.name,
            email: this.participar.email,
            celular: this.participar.celular
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => { 
            if(response.data.erro==0){
                this.isReturn = response.data.isreturn
                this.isReturntxt = response.data.isreturntxt
            } 
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  },
  mounted () {
    this.$http
      .get('sorteio-item')
      .then(response => {
        this.item.id = response.data.item.id
        this.item.name = response.data.item.name
        this.item.foto = response.data.item.foto
        this.item.txtcurto = response.data.item.txtcurto
        this.item.txt = response.data.item.txt
        this.item.datasorteio = response.data.item.datasorteio
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>

#app-sorteios-item .box-classificado-00-pd-img { text-align: center; background-color: #1b3781; margin: 5px 0; }
#app-sorteios-item .box-classificado-00-pd-img img { height: 150px; }

#app-sorteios-item .sub-me-pd-01-ative { color: #FF671B !important; }
#app-sorteios-item .sub-me-pd-01-ative path { color: #FF671B !important; }

#app-sorteios-item .box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

#app-sorteios-item  { background-color: #1B3781; }
#app-sorteios-item  .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 25Wpx; }

#app-sorteios-item  .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#app-sorteios-item  .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#app-sorteios-item  .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#app-sorteios-item  .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#app-sorteios-item  .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

#app-sorteios-item  .box-app-pd-01-info {  text-align: center; color: #FFF; padding-top: 15px; }
#app-sorteios-item  .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 22px; padding-bottom: 10px; padding-right: 28px; text-align: right; }
#app-sorteios-item  .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }


  
#app-sorteios-item  .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-sorteios-item  .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-sorteios-item  .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-sorteios-item  #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-sorteios-item  #form-text-esqueci-senha { width: 100%; text-align: right; }

#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-menu .btn { padding: 2px 7px; background-color: #FFF; border-color: #FFF; }
#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-menu .btn svg {  height: 15px; color: #FF671B; } 
#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #FFF; }
#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; }
#app-sorteios-item  .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 25px; height: 25px; color: #FFF;  }


#app-sorteios-item .box-sorteios-list { padding: 10px 25px;  }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title { padding-left: 0 !important; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd { padding: 10px 0; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-01 { font-size: 14px;}
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-02 { font-size: 12px; color: #1B3781; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-seta { padding-top: 40px; }
#app-sorteios-item .box-sorteios-list .box-sorteios-list-item .box-sorteios-list-item-seta a { color: #ccc; font-size: 22px; }
#app-sorteios-item .box-sorteios-list .visto-check { background-color: #f1f1f1 !important; }



#app-sorteios-item .box-sorteios-list2 { padding: 5px 25px 0; }
#app-sorteios-item .box-sorteios-list2 .box-sorteios-list-item2 { margin: 0 !important;    }
#app-sorteios-item .box-sorteios-list2 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd { padding: 10px 0 0px; }
#app-sorteios-item .box-sorteios-list2 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; color:#e46805; }

#app-sorteios-item .box-sorteios-list3 { padding: 15px 25px 0; }
#app-sorteios-item .box-sorteios-list3 .box-sorteios-list-item2 { margin: 0 !important;    }
#app-sorteios-item .box-sorteios-list3 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd { padding: 10px 0 0; }
#app-sorteios-item .box-sorteios-list3 .box-sorteios-list-item2 .box-sorteios-list-item-title-pd .box-sorteios-list-item-title-00 { font-weight: bold; color:#1B3781 }


#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx { margin: 0 !important;  }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd { padding: 10px; }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd .box-sorteio-list-item-title-00 { font-weight: bold; }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd .box-sorteio-list-item-title-01 { font-size: 14px;}
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd .box-sorteio-list-item-title-03 { font-size: 12px; color: #1B3781; padding-top: 10px;  }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-seta { padding-top: 40px; }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-seta a { color: #ccc; font-size: 22px; }
#app-sorteios-item .box-sorteio-list .visto-check { background-color: #f1f1f1 !important; }


#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd .box-sorteio-list-item-title-03 { padding: 15px 0; }
#app-sorteios-item .box-sorteio-list .box-sorteio-list-itemx .box-sorteio-list-item-title-pd .box-sorteio-list-item-title-03 a{background-color: #e46805 !important; border: 0; width: 100%; }


#form-validar-dados-sorteio button {background-color: #e46805 !important; border: 0; width: 100%; }
#box-form-participar-sorteio-01 { padding-top: 20px; }
</style>