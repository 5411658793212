<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-classificadosy">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            Convites
                            <img src="https://sadesenvolvimento.com.br/app-apcef/public/assets/img/icones-f-azul/4.png" style="width:30px; height: 30px;vertical-align: sub;" />
                        </div>
                    </div>
                    <div class="box-form-dinamic" id="box-form-dinamic">
                         
                        <div class="sub-menu-pd-01">
                             
                           
                          <div class="sub-me-pd-01-item" v-on:click="openemitirconvite()">
                              <div class="sub-me-pd-01-item-ico">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                  </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Emitir
                              </div>
                          </div>
                          <div class="sub-me-pd-01-item sub-me-pd-01-ative">
                              <div class="sub-me-pd-01-item-ico">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="currentColor" class="bi bi-card-list" viewBox="0 0 16 16">
                                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                      <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                  </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Convites
                              </div>
                          </div>
                        </div>
                        <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                            <div class="row">
                              <div><b>Convites emitidos no mês:</b></div>
                              <div>0 / 4</div>
                            </div><br>
                            <div class="row">
                              <div><b>Convites emitidos:</b></div>
                              <div>
                                  <div class="convite-list row">
                                    <div class="col-7">
                                      <div class="convite-list-name">Samir Pinheiro</div>
                                      <div class="convite-list-unidade">Unidade Bueno</div>
                                    </div>
                                    
                                    <div class="col-5">
                                      <div class="convite-list-doc">10080110614</div> 
                                      <div class="convite-list-data">20/07/2022</div>
                                    </div>
                                    
                                  </div>
                              </div>
                            </div>  
                        </div> 
                    </div>
                </div>

        </section>

        
    </div>
</template>

 
<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewConvites',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      info: ''
    };
  },
  methods: {
    openclassificado() {
      barramento.$emit('alterarTela', 'Classificadositem'); 
    }, 
    openemitirconvite() {
      barramento.$emit('alterarTela', 'Convitesitem'); 
    },
  },
  mounted () {
    this.$http
      .get('products')
      .then(response => {
        this.info = response.data.products[0].title
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
.convite-list { margin: 5px 0; }
.convite-list .convite-list-name { font-size: 14px; }
.convite-list .convite-list-doc { font-size: 14px; text-align: right; }
.convite-list .convite-list-unidade { font-size: 12px;  }
.convite-list .convite-list-data { font-size: 12px; text-align: right; }

.sub-menu-pd-01 { width: 100%; display: flex;; margin: auto; height: 70px; border-radius: 8px; box-shadow:1px 1px 5px #DEDEDE; background-color: #FFF; padding: 10px; margin-top: -60px;}
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico { height: 30px; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico path { color: #1B3781; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-txt { font-size: 13px; }
.sub-menu-pd-01 .sub-me-pd-01-item  { width: 70px; text-align: center; }

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }

.box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

.box-classificado-00-pd { width: 100%; border: 1px solid #f3f3f3; border-radius:8px; }
.box-classificado-00-pd .box-classificado-00-pd-img { }
.box-classificado-00-pd .box-classificado-00-pd-info { display: flex; }
.box-classificado-00-pd .box-classificado-00-pd-img img { max-width: 100%; border-radius:8px 8px 0 0;}
.box-classificado-00-pd .box-classificado-00-pd-info-title { padding: 5px; font-size: 12px; font-weight: bold; }
.box-classificado-00-pd .box-classificado-00-pd-info-valor { padding: 5px; font-size: 12px; color:#1b3781; }

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }

.box-form-dinamic-00 { padding-top: 20px; padding-bottom:50px; }

#app-classificadosy { background-color: #1B3781; }
#app-classificadosy .box-form-dinamic { background-color: #FFF; border-radius: 30px 30px 0 0; padding: 30px; }

#app-classificadosy .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#app-classificadosy .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#app-classificadosy .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#app-classificadosy .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#app-classificadosy .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

#app-classificadosy .box-app-pd-01-info {  text-align: center; color: #FFF; padding-top: 15px; }
#app-classificadosy .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 22px; padding-bottom: 50px; padding-right: 28px; text-align: right; }
#app-classificadosy .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }


  
#app-classificadosy .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
#app-classificadosy .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
#app-classificadosy .box-form-dinamic #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;  }
#app-classificadosy #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

#app-classificadosy #form-text-esqueci-senha { width: 100%; text-align: right; }

#app-classificadosy .box-app-pd-01-ico-01 #btn-header-menu .btn { padding: 2px 7px; background-color: #FFF; border-color: #FFF; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-menu .btn svg {  height: 15px; color: #FF671B; } 
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #FFF; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; }
#app-classificadosy .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 25px; height: 25px; color: #FFF;  }

</style>